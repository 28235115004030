import React, { useEffect, useState } from "react";
import BiogiLogo from "../assets/images/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import Resume from "../assets/pdf/My CV.pdf";

const Header = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("/");
  const [isFixed, setIsFixed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [subActiveIndex, setSubActiveIndex] = useState(null);

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const isActive = (path) => (activeItem === path ? "menu-active" : "");

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition >= 20) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setSubActiveIndex(null); // Reset sub menu when main menu is toggled
  };

  const toggleSubAccordion = (index) => {
    setSubActiveIndex(subActiveIndex === index ? null : index);
  };
  return (
    <>
      {/* <!-- ====================================== Main Menu ===================================== --> */}
      <header
        id="top-navbar"
        className={`top-navbar ${isFixed ? "fixed" : ""}`}
      >
        <div className="container">
          <div className="top-navbar_full">
            <div className="back-btn">
              <Link to="/">
                <img src={BiogiLogo} alt="logo" />
              </Link>
            </div>
            <div className="hell">
              <ul className="top-navbar-title">
                <li>
                  <NavLink to="/" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeClassName="active">
                    About
                  </NavLink>
                  <ul className="dropdown_menu dropdown_menu--animated dropdown_menu-7">
                    <li className="dropdown-submenu">
                      <ul className="dropdown-submenu-main id-portfolio">
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown-submenu">
                      <ul className="dropdown-submenu-main id-single-portfolio">
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                        <li className="dropdown_item-submenu">
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">
                    {" "}
                    <NavLink to="/contact" activeClassName="active">
                      Contact
                    </NavLink>
                  </Link>
                </li>
              </ul>
              <Link to={Resume} rel="noreferrer" target="_blank">
                <div className="cv-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_25_74)">
                      <path
                        d="M12 3V15"
                        stroke="#0F141C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 11L12 15L8 11"
                        stroke="#0F141C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12"
                        stroke="#0F141C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </div>
              </Link>
              <div className="outer-menu">
                <input
                  className="checkbox-toggle"
                  type="checkbox"
                  name="checkbox"
                />
                <div className="hamburger">
                  <div></div>
                </div>
                {/* <!-- ====================================== Side Menu ===================================== --> */}
                <div className="menu">
                  <div>
                    <nav>
                      <div className="container">
                        <div className="row sidemenu-main">
                          <div className="col-lg-2">
                            <div className="menu-text">
                              <div className="side-menu-text">
                                <p>MENU</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 side-menu-scroll">
                            <div className="heading2">
                              <Link to="/">Home</Link>
                            </div>
                            <div className="heading2">
                              <Link to="/about">About</Link>
                            </div>
                            <div className="heading2">
                              <Link to="/contact">Contact</Link>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="cont-info">
                              <div className="item">
                                <p className="sub-title-address">Address</p>
                                <p className="geek-alto">
                                  56/6 B, Yatirawana.
                                  <br />
                                  Wattegama, Srilanka
                                </p>
                              </div>
                              <div className="item">
                                <p className="sub-title-address">Contact Us</p>
                                <p>
                                  <Link to="/mailto:biogi@mail.com">
                                    admin@isoft.com.lk
                                  </Link>
                                </p>
                                <p className="underline">
                                  <Link to="tel:+94702142439">
                                    +94 702 142 439
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* <!-- ====================================== Side Menu End===================================== --> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ====================================== Main Menu End===================================== --> */}
    </>
  );
};
export default Header;
